import { Helmet } from 'react-helmet-async';
import profileImage from '../../assets/images/profile.png';

const SEO = ({ title, description, image, url }) => {
  // Base URLs and defaults
  const baseUrl = 'https://ahmadrajput.com';
  const defaultTitle = 'Ahmad Rajput | Automations & Integrations Specialist';
  const defaultDescription = 'Experienced Automations & Integrations Specialist with expertise in cloud solutions, AI integration, and workflow automation. Building efficient, scalable solutions that bridge complex systems with user-friendly interfaces.';
  const defaultImage = `${baseUrl}${profileImage}`;

  // Social profiles
  const socialProfiles = {
    linkedin: 'https://www.linkedin.com/in/ahmad-bin-khalil',
    github: 'https://github.com/AhmadBinKhalil'
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <link rel="canonical" href={url || baseUrl} />

      {/* OpenGraph Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Ahmad Rajput" />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:url" content={url || baseUrl} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={image || defaultImage} />

      {/* Professional Meta Tags */}
      <meta name="keywords" content="Ahmad Rajput, automation specialist, integration specialist, workflow automation, cloud solutions, AI integration, process automation, system integration, automation engineer" />
      <meta name="author" content="Ahmad Rajput" />
      <meta name="robots" content="index, follow" />

      {/* Schema.org structured data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Person",
          "name": "Ahmad Rajput",
          "url": baseUrl,
          "image": defaultImage,
          "sameAs": [
            socialProfiles.linkedin,
            socialProfiles.github
          ],
          "jobTitle": "Automations & Integrations Specialist",
          "description": defaultDescription,
          "knowsAbout": [
            "Workflow Automation",
            "System Integration",
            "Cloud Solutions",
            "AI Integration",
            "Process Optimization"
          ]
        })}
      </script>
    </Helmet>
  );
};

export default SEO; 