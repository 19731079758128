import React from 'react';
import AnimatedCursor from "react-animated-cursor";
import Navigation from './components/Navigation/Navigation';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO/SEO';
import './App.scss';

function App() {
  return (
    <HelmetProvider>
      <div className="app">
        <SEO />
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: '#00b4d8'
          }}
          outerStyle={{
            border: '3px solid #00b4d8'
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="submit"]',
            'textarea',
            'button',
            '.link',
            '.navigation__logo',
            '.projects__item',
            '.social-links a'
          ]}
        />
        <Navigation />
        <Hero />
        <About />
        <Skills />
        <Projects />
        <Contact />
      </div>
    </HelmetProvider>
  );
}

export default App; 