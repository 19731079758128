import { motion } from 'framer-motion';
import { FaRobot, FaBrain, FaTools } from 'react-icons/fa';
import './About.scss';

const About = () => {
  const cards = [
    {
      icon: <FaTools />,
      title: "Automation Expert",
      description: "Specializing in no-code automation platforms like Zapier, Make.com, and n8n to create efficient workflow solutions."
    },
    {
      icon: <FaRobot />,
      title: "AI Scientist",
      description: "Leveraging AI technologies and integrating them with automation tools to create intelligent solutions."
    },
    {
      icon: <FaBrain />,
      title: "Integration Specialist",
      description: "Connecting different platforms and systems seamlessly through advanced automation techniques."
    }
  ];

  return (
    <section id="about" className="about">
      {/* Gradient Container */}
      <div className="gradient-container">
        <motion.div 
          className="gradient-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>

      <motion.div 
        className="about__content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.1 }}  // Added amount threshold
        transition={{ duration: 0.8 }}
      >
        <h2>About Me</h2>
        
        <div className="about__text">
          <p>
            I'm a passionate Automations & Integrations Specialist with a knack for creating efficient, scalable solutions. 
            My journey in tech has been driven by a desire to bridge the gap between complex systems and user-friendly interfaces.
          </p>
        </div>

        <div className="about__cards">
          {cards.map((card, index) => (
            <motion.div 
              className="card"
              key={index}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ 
                y: -10,
                boxShadow: '0 10px 20px rgba(0, 180, 216, 0.2)'
              }}
            >
              <div className="card__icon">
                {card.icon}
              </div>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="about__stats">
          {[
            { number: "3+", text: "Years Experience" },
            { number: "50+", text: "Projects Completed" },
            { number: "100%", text: "Client Satisfaction" }
          ].map((stat, index) => (
            <motion.div 
              key={index}
              className="stat"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <h3>{stat.number}</h3>
              <p>{stat.text}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default About;