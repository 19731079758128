import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRobot, FaCalendarAlt, FaChartLine, FaFileAlt,
         FaDatabase, FaFileContract, FaUserPlus, FaMap, FaGift, FaTimes, FaArrowRight } from 'react-icons/fa';
import './Projects.scss';

const projects = [
  {
    name: "Blips Employee Survey Automation",
    description: "This project automates the distribution, completion, and collection of employee surveys. It integrates Google Sheets to manage survey forms, AWS Lambda for survey generation, and AWS S3 for storing survey files. Additionally, it sends an automated email via Gmail with personalized survey links for each employee. The workflow also supports multiple survey types, including peer, manager, and self-reviews, enabling a comprehensive review process.",
    icon: <FaFileAlt />,
    emoji: ""
  },
  {
    name: "AirCall Availability Management",
    description: "This workflow automates availability management for AirCall users based on their Google Calendar schedules. It regularly checks each user's calendar for ongoing events and adjusts their availability status in AirCall accordingly. If a user is in a meeting, they are marked as 'unavailable' in AirCall, and once free, their status is updated back to 'available.' This helps teams manage their availability dynamically, ensuring smooth communication and reducing missed calls.",
    icon: <FaCalendarAlt />,
    emoji: ""
  },
  {
    name: "Wix Blog Post Analytics Sync",
    description: "This project automates the collection and updating of blog post analytics from Wix. It retrieves metrics like views, likes, and comments and appends or updates them in a Google Sheets document. This workflow streamlines content performance tracking, providing an organized view of blog post engagement metrics for data-driven decisions on content strategy.",
    icon: <FaChartLine />,
    emoji: ""
  },
  {
    name: "Cover Letter Generator",
    description: "This project automates the creation of personalized cover letters using data provided through a webhook, which includes details such as the company name, job title, and job description. The workflow uses the OpenAI model to generate a unique cover letter content tailored to the job description and the user's CV information. The generated cover letter is then stored in Google Docs and Google Drive, organized based on the company name.",
    icon: <FaRobot />,
    emoji: ""
  },
  {
    name: "Supabase Document Vector Store with RAG Agent",
    description: "This workflow not only facilitates the embedding and storage of document data in a Supabase vector store but also integrates a Retrieval-Augmented Generation (RAG) agent. The RAG agent allows the workflow to pull relevant information from stored documents to enhance responses, using a combination of Zendesk API data retrieval, OpenAI embeddings, and Supabase vector storage. Text data is processed, split into manageable chunks, embedded for semantic search, and then indexed in the vector database. This setup is highly efficient for knowledge management applications, enabling quick, contextually accurate information retrieval and response generation.",
    icon: <FaDatabase />,
    emoji: ""
  },
  {
    name: "DeathCare Legal Document Automation",
    description: "This workflow automates the generation of legal documents for DeathCare settlements based on client-provided information. By gathering essential data from clients, the workflow can auto-populate legal templates, creating documents specific to each case. This ensures accurate, quick, and compliant document creation for DeathCare clients, minimizing manual input and reducing the risk of errors in legal documents.",
    icon: <FaFileContract />,
    emoji: ""
  },
  {
    name: "Attorney Onboarding for EstateGuru via HubSpot",
    description: "This workflow streamlines the attorney onboarding process by using a custom form integrated into HubSpot. It collects information necessary for onboarding new attorneys at EstateGuru, centralizing the data in HubSpot's CRM. The automated process includes validating the collected information, setting up CRM records, and notifying the relevant team members. This onboarding automation enhances data consistency, reduces administrative workload, and provides a seamless experience for both the attorneys and the onboarding team.",
    icon: <FaUserPlus />,
    emoji: ""
  },
  {
    name: "Google Maps Scraper with 2FA Authentication",
    description: "This workflow serves as a fully-fledged Google Maps data extraction platform, hosted entirely within n8n. It not only scrapes essential business information such as names, addresses, ratings, and categories but also incorporates advanced 2-Factor Authentication (2FA) for both mobile and email verification. This robust security setup ensures safe access while automating data extraction from Google Maps. Ideal for lead generation, market analysis, and competitive intelligence, this workflow provides secure and efficient data scraping functionality.",
    icon: <FaMap />,
    emoji: ""
  },
  {
    name: "White Elephant Game Website",
    description: "This workflow represents a complete, interactive White Elephant game platform, hosted entirely within n8n. It includes user registration, game management, and a front-end interface for players to interact with. The platform facilitates the full White Elephant experience, from allowing participants to pick and 'steal' gifts according to game rules to real-time updates and notifications. Perfect for remote or hybrid holiday gatherings, this workflow makes hosting and managing the White Elephant game seamless and engaging, all within the n8n environment.",
    icon: <FaGift />,
    emoji: ""
  }
];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <section id="projects" className="projects">
      <div className="gradient-container">
        <motion.div 
          className="gradient-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>

      <motion.div 
        className="projects__content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
        <h2>Projects</h2>
        <div className="projects__list">
          {projects.map((project, index) => (
            <motion.div 
              key={index} 
              className="projects__item"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ 
                y: -10,
                boxShadow: '0 10px 20px rgba(0, 180, 216, 0.2)'
              }}
              onClick={() => setSelectedProject(project)}
            >
              <div className="projects__item-header">
                <div className="icon-group">
                  <span className="icon">{project.icon}</span>
                  <span className="emoji">{project.emoji}</span>
                </div>
                <span className="read-more">
                  Read More <FaArrowRight />
                </span>
              </div>
              <h3>{project.name}</h3>
              <p className="projects__item-preview">
                {project.description.substring(0, 100)}...
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      <AnimatePresence>
        {selectedProject && (
          <>
            <motion.div 
              className="projects__overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedProject(null)}
            />
            <motion.div 
              className="projects__modal"
              initial={{ 
                opacity: 0,
                top: '60%',
              }}
              animate={{ 
                opacity: 1,
                top: '50%',
              }}
              exit={{ 
                opacity: 0,
                top: '60%',
              }}
              transition={{ 
                type: "spring",
                damping: 30,
                stiffness: 300
              }}
            >
              <button 
                className="close-button"
                onClick={() => setSelectedProject(null)}
              >
                <FaTimes />
              </button>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="icon">{selectedProject.icon}</span>
                  <span className="emoji">{selectedProject.emoji}</span>
                </div>
                <h3>{selectedProject.name}</h3>
                <p>{selectedProject.description}</p>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Projects;