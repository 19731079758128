import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';
import './Contact.scss';

const Contact = () => {
  const form = useRef();
  const [status, setStatus] = useState({ message: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ message: '', type: '' });

    try {
      const formData = new FormData(form.current);
      const response = await fetch('https://www.ahmadrajput.com/contact-me', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        setStatus({
          message: 'Message sent successfully!',
          type: 'success'
        });
        form.current.reset();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setStatus({
        message: 'Failed to send message. Please try again.',
        type: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="contact">
      <div className="gradient-container">
        <motion.div 
          className="gradient-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>

      <motion.div 
        className="contact__content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <h2>Get In Touch</h2>
        
        <div className="contact__container">
          <div className="contact__info">
            <h3>Let's Connect</h3>
            <p>Feel free to reach out for collaborations or just a friendly hello</p>
            
            <div className="contact__social">
              <motion.a
                href="https://www.linkedin.com/in/ahmad-bin-khalil"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaLinkedin /> LinkedIn
              </motion.a>
              <motion.a
                href="https://github.com/AhmadBinKhalil"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaGithub /> GitHub
              </motion.a>
              <motion.a
                href="mailto:ahmadkhalil2142@gmail.com"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaEnvelope /> Email
              </motion.a>
            </div>
          </div>

          <form ref={form} onSubmit={handleSubmit} className="contact__form">
            <div className="form__group">
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="form__group">
              <input
                type="email"
                name="user_email"
                placeholder="Your Email"
                required
              />
            </div>
            <div className="form__group">
              <textarea
                name="message"
                placeholder="Your Message"
                required
              />
            </div>
            <motion.button
              type="submit"
              disabled={isSubmitting}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </motion.button>
            {status.message && (
              <div className={`form__status ${status.type}`}>
                {status.message}
              </div>
            )}
          </form>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact; 