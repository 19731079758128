import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import TypeWriter from '../TypeWriter/TypeWriter';
import profileImage from '../../assets/images/profile.png';
import './Hero.scss';

const Hero = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <motion.section
      id="home"
      className="hero"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Particles
        className="particles"
        init={particlesInit}
        options={{
          background: {
            color: "#121212"
          },
          particles: {
            number: { 
              value: 80, 
              density: { 
                enable: true, 
                value_area: 800 
              } 
            },
            color: { 
              value: "#00b4d8" 
            },
            opacity: { 
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: { 
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#00b4d8",
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 0.8,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { 
                enable: true, 
                mode: "grab"
              },
              onclick: { 
                enable: true, 
                mode: "push"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 140,
                line_linked: {
                  opacity: 1
                }
              },
              push: {
                particles_nb: 4
              }
            }
          },
          retina_detect: true
        }}
      />

        <div className="gradient-container">
          <motion.div 
            className="gradient-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          />
        </div>

      <div className="hero__content">
        <motion.div
          className="hero__profile"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          whileHover={{ 
            scale: 1.05,
            boxShadow: '0 0 25px rgba(0, 180, 216, 0.5)'
          }}
        >
          <img src={profileImage} alt="Ahmad Rajput" />
        </motion.div>


        <motion.h1
          className="glow-on-hover"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Ahmad Rajput
        </motion.h1>
        
        <motion.h2
          className="glow-on-hover"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <TypeWriter text="Automations & Integrations Specialist" />
        </motion.h2>

        <motion.div 
          className="social-links"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          <motion.a
            href="https://github.com/AhmadBinKhalil" // Replace with your GitHub URL
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ 
              scale: 1.1,
              color: '#00b4d8',
              boxShadow: '0 0 15px rgba(0, 180, 216, 0.5)'
            }}
            whileTap={{ scale: 0.95 }}
          >
            <FaGithub />
          </motion.a>
          <motion.a
            href="https://linkedin.com/in/ahmad-bin-khalil" // Replace with your LinkedIn URL
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ 
              scale: 1.1,
              color: '#00b4d8',
              boxShadow: '0 0 15px rgba(0, 180, 216, 0.5)'
            }}
            whileTap={{ scale: 0.95 }}
          >
            <FaLinkedin />
          </motion.a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Hero;