import React from 'react';
import { motion } from 'framer-motion';
import { FaCloud, FaRobot, FaBrain, FaDatabase, FaDocker } from 'react-icons/fa';
import './Skills.scss';

const Skills = () => {
  const skillCategories = [
    {
      title: "Cloud & DevOps",
      icon: <FaCloud />,
      skills: [
        "AWS Certified Cloud Practitioner",
        "AWS Lambda",
        "Docker",
        "Cloud Architecture"
      ]
    },
    {
      title: "Automation & Integration",
      icon: <FaRobot />,
      skills: [
        "n8n (Nodemation)",
        "Workflow Automation",
        "Web Scraping",
        "Process Integration"
      ]
    },
    {
      title: "Machine Learning",
      icon: <FaBrain />,
      skills: [
        "TensorFlow & Keras",
        "Scikit-Learn",
        "Predictive Modeling",
        "Neural Networks"
      ]
    },
    {
      title: "Data Engineering",
      icon: <FaDatabase />,
      skills: [
        "SQL & Database Design",
        "Data Analytics",
        "Pandas",
        "Large Dataset Handling"
      ]
    }
  ];

  return (
    <section id="skills" className="skills">
      <div className="gradient-container">
        <motion.div 
          className="gradient-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>

      <motion.div 
        className="skills__content"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.8 }}
      >
        <h2>Skills & Expertise</h2>
        
        <div className="skills__grid">
          {skillCategories.map((category, index) => (
            <motion.div 
              key={index}
              className="skills__category"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ y: -10,
                boxShadow: '0 10px 20px rgba(0, 180, 216, 0.2)' }}
            >
              <div className="category__icon">
                {category.icon}
              </div>
              <h3>{category.title}</h3>
              <ul>
                {category.skills.map((skill, skillIndex) => (
                  <motion.li 
                    key={skillIndex}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: (index * 0.1) + (skillIndex * 0.1) }}
                  >
                    {skill}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Skills; 